require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import MicroModal from "micromodal";
window.MicroModal = MicroModal;
const axios = require("axios");
window.Noty = require("noty");
import Glide from "@glidejs/glide";
window.Glide = Glide;

document.addEventListener("turbolinks:load", function () {
  if (location.href.indexOf("#") == -1) {
    window.scrollTo(0, 0);
  }

  let body = document.getElementsByTagName("html")[0];

  const trigger = document.getElementById("menu-trigger");
  if (trigger) {
    trigger.addEventListener("click", function () {
      body.classList.add("menu-opened");
    });
  }

  const closeMenu = document.getElementById("close-menu");
  if (closeMenu) {
    closeMenu.addEventListener("click", function () {
      body.classList.remove("menu-opened");
    });
  }

  let buttons = document.querySelectorAll("nav .links a");
  if (buttons.length) {
    buttons.forEach((element) => {
      element.addEventListener("click", () => {
        body.classList.remove("menu-opened");
      });
    });
  }

  const hasCounter = document.querySelectorAll(".has-counter");
  hasCounter.forEach((counter, i) => {
    animateValue(counter, 0, counter.dataset.value, 1500 + i * 200);
  });

  const hasGlide = document.querySelector(".glide");
  if (hasGlide) {
    new Glide(".glide", {
      autoplay: "4000",
      type: "carousel",
      hoverpause: false,
      animationDuration: 1500,
    }).mount();
  }
});

function animateValue(obj, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
